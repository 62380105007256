
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import QuoteBar from '@/components/QuoteBar.vue'
import Tab from '@/components/Tab.vue'
import { useQuote } from '@/modules/market/market'
import TheCommissionCreateButton from '@/modules/trade/components/TheCommissionCreateButton.vue'
import TheTradeCost from '@/modules/trade/components/TheTradeCost.vue'
import TheTradeLimits from '@/modules/trade/components/TheTradeLimits.vue'
import TheTradePaymentMethods from '@/modules/trade/components/TheTradePaymentMethods.vue'
import ThePositionCreateButton from '@/modules/trade/components/ThePositionCreateButton.vue'
import TheTradeProducts from '@/modules/trade/components/TheTradeProducts.vue'
import TheTradeTargetPrice from '@/modules/trade/components/TheTradeTargetPrice.vue'
import Order from '@/modules/trade/Order'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { TradeDirection } from '@/types'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import Icon from '@/components/Icon.vue'
import {
  defineComponent, onBeforeUnmount,
  provide, ref,
  shallowRef, watch,
} from 'vue'
import { ESpecialType } from '@/modules/market/market.api'
import TheTradeMethod from '@/modules/fastTrade/components/TheTradeMethod.vue'
import { checkWallet } from '@/modules/trade/trade.api'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'
import { add } from 'essential/tools/math'
import { off, on } from 'essential/tools/event'
import { events } from '@/config'

export default defineComponent({
  name: 'Create',
  components: {
    TheTradeMethod,
    QuoteBar,
    DemoAccountTip,
    Button,
    TheCommissionCreateButton,
    ThePositionCreateButton,
    TheTradeLimits,
    Line,
    TheTradeCost,
    TheTradePaymentMethods,
    TheTradeTargetPrice,
    TheTradeProducts,
    Tab,
    PageWithHeader,
    Icon,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const isCommission = shallowRef(false)
    const type = Number(props.type) as TradeDirection
    const order = new Order(type)
    const symbol = useQuote(props.code)
    const credit = ref(0)
    const unCredit = ref(0)

    const contractCode = props.code as string
    const sepcialType = shallowRef(ESpecialType.NORMAL)

    const paymethod = ref(0)

    checkWallet({ type: 1, offset: 0 }).then((res: any) => {
      if (res) {
        const { normalCredit, limitCredits } = res
        const { normalAmount, voucherId } = normalCredit
        let tempCredit = Number(normalAmount)
        let tempUnCredit = 0
        let tempIds = voucherId
        limitCredits?.forEach((item: any) => {
          if (item.contracts.includes(contractCode)) {
            tempCredit = add(tempCredit, item.amount)
            tempIds = tempIds + ',' + item.voucherId
          } else {
            tempUnCredit = add(tempUnCredit, item.amount)
          }
        })
        credit.value = tempCredit
        unCredit.value = tempUnCredit
        order.voucherIds.value = tempIds
      }
    })

    on(events.usecredit, checkWallet)
    onBeforeUnmount(() => {
      off(events.usecredit, checkWallet)
    })

    // checkIsSpecial({
    //   contract: props.code as string,
    // }).then(res => {
    //   sepcialType.value = res.specialIdentification
    // })

    const dynamicSetting = {
      [TradeDirection.BUY]: {
        label: 'symbol_22',
        themeClass: 'success',
        labelDefault: 'BUY',
      },
      [TradeDirection.SELL]: {
        label: 'symbol_23',
        themeClass: 'danger',
        labelDefault: 'SELL',
      },
    }

    const themeClass = dynamicSetting[type].themeClass

    provide('themeClass', themeClass)
    provide('order', order)

    watch(() => paymethod.value, (val) => {
      order.useCoupon.value = !!val
      setTimeout(() => {
        showAlert(translate(val ? 'credit_10' : 'credit_11', { value: '1:' + order.leverage.value }),
          { wrapperClass: 'toast-dark', closeTime: 1000, clickDisable: true })
      })
    })

    return {
      symbol,
      isCommission,
      title: dynamicSetting[type],
      themeClass,
      contractCode,
      sepcialType,
      ESpecialType,
      paymethod,
      credit,
      unCredit
    }
  },
})
