
import Button from '@/components/Button.vue'
import { createCommissionOrder } from '@/modules/trade/trade'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCommissionCreateButton',
  components: { Button },
  setup () {
    const [create, progress] = createCommissionOrder()

    return {
      create,
      progress,
    }
  },
})
