import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t = _resolveComponent("t")
  const _component_Button = _resolveComponent("Button")

  return (_openBlock(), _createBlock(_component_Button, {
    onClick: _ctx.create,
    progress: _ctx.progress
  }, {
    default: _withCtx(() => [
      _createVNode(_component_t, {
        path: "placeorder_19",
        custom: ""
      }, {
        default: _withCtx(({td}) => [
          _createTextVNode(_toDisplayString(td || 'Place order'), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClick", "progress"]))
}