
import Radio from '@/components/Radio.vue'
import Order from '@/modules/trade/Order'
import { checkUsableCoupon } from '@/modules/trade/trade'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import * as R from 'ramda'
import { computed, defineComponent, inject, shallowRef, watchEffect } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { openDialog, showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'TheTradePaymentMethods',
  components: {
    Pic,
    Icon,
    TheAsset,
    Radio,
    AccountRelated,
  },
  props: {
    price: String,
    isCommission: Boolean
  },
  setup () {
    const usableCouponCount = shallowRef(0)
    const order = inject('order') as Order
    const useCoupon = computed(() => order.useCoupon)
    const couponPrice = shallowRef(0)

    watchEffect(() => {
      const price = order?.getProduct().value?.price
      couponPrice.value = Number(price)
      // checkUsableCoupon().then(resp => {
      //   usableCouponCount.value = R.length(
      //     R.filter(R.propEq('price', Number(price)), resp))
      //   if (!usableCouponCount.value) order.useCoupon.value = false
      // })
    })

    const openCreditRuleDialog = () => {
      // showAlert(translate('credit_10'), {autoClose: false, wrapperClass: 'toast-dark'})
    }

    return {
      order,
      useCoupon,
      usableCouponCount,
      couponPrice,
      useCash (state: boolean) {
        order.useCoupon.value = !state
      },
      openCreditRuleDialog
    }
  },
})
